.deleted-icon {
  position: absolute;
  right: 8px;
  top: 28px;
  width: 25px;
  height: 25px;
}

.css-13cymwt-control {
  width: 320px !important;
}

.popup {
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.popup-inner2 {
  max-width: 400px;
}

.popup-inner2 {
  width: 90%;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  border-top: 8px solid #ff6800;
  background: #fff;
}

.popup-close {
  width: 30px;
  height: 30px;
  padding-top: 0;
  display: inline-block;
  position: absolute;
  top: 0;
  outline: none;
  border: 0;
  right: 0;
  transition: all 0.25s ease;
  transform: translate(50%, -50%);
  border-radius: 1000px;
  background: #ff6800;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  line-height: 100%;
  color: #fff;
}

.treatment-form .inputbox {
  width: 100% !important;
  height: auto !important;
  text-align: left;
}

.treatment-form .inputbox textarea::placeholder {
  color: #000;
}

.treatment-form .querybox {
  width: 100%;
  height: 80px;
  padding-top: 20px;
  padding-left: 16px;
}

.treatment-form .treatmentbox {
  width: 100%;
  height: 55px;
  padding-top: 0px;
  padding-left: 10px;
}

.treatment-form select {
  width: 100%;
  border: 1px solid #dadada;
}

.popup h2 {
  color: #000 !important;
  font-size: 21px !important;
  line-height: 25px !important;
  width: 100% !important;
  margin: 0 0 15px !important;
  font-weight: 600;
}

.home-button {
  background: #ff6800;
  font-family: Helvetica Neue Light;
  color: #fff !important;
  border: 2px solid #ff6800;
  margin: 10px 0 0;
  font-weight: 700;
  font-size: 20px;
  border-radius: 8px;
  padding: 10px 62px 10px 14px;
  position: relative;
  width: 100%;
  text-align: left;
}

.popup .home-button {
  padding: 8px 62px 8px 14px;
}

.home-button img {
  width: 38px;
  position: absolute;
  background: #fff;
  right: 5px;
  top: 4px;
  padding: 10px;
  border-radius: 6px;
  margin: 0 !important;
}

.treatment-form label {
  font-family: Helvetica Neue Medium !important;
  font-size: 14px !important;
  color: #575757 !important;
}

.patient-dashboard-case-left li.active .updates-img {
  background: green; /* Green background when active */
  color: white;
  font-size: 12px;
  font-weight: bold;
  position: relative;
  top: 22px;
  left: -34px;
  width: 20px;
  height: 20px;
}
.patient-dashboard-case-left li.active .updates-img::after {
  content: "✓"; /* Checkmark symbol */
  color: white; /* Checkmark color */
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes mymove {
  from {
    background-color: #ff6800;
  }

  to {
    background-color: #fff;
  }
}

.basic-multi-select {
  .select__control {
    border: 1px solid #ccc;
    box-shadow: none;
    width: 100% !important;

    &:hover {
      border-color: #ff6800;
    }
  }

  .select__control--is-focused {
    border-color: #ff6800;
    box-shadow: 0 0 0 1px #ff6800;
  }

  .select__multi-value {
    background-color: #ff6800;
    color: white;

    .select__multi-value__label {
      color: white;
    }

    .select__multi-value__remove {
      color: white;

      &:hover {
        background-color: #e66000;
        color: white;
      }
    }
  }

  .select__menu {
    border-radius: 0 0 8px 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .select__option {
    &:hover {
      background-color: #ff6800;
      color: white;
    }

    &--is-focused {
      background-color: #ff6800;
      color: white;
    }

    &--is-selected {
      background-color: #ff6800;
      color: white;
    }
  }
}

.send-email-button {
  display: block;
  width: 100%;
  padding: 10px 0;
  background-color: #ff6800;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.send-email-button:hover {
  background-color: #e66000;
}

.send-email-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 104, 0, 0.4);
}

.patient-dashboard-msg select {
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 16px;
  color: #333;
  outline: none;
  transition: border-color 0.3s ease;
}

.patient-dashboard-msg select:focus {
  border-color: #ff6800;
  /* Highlight border on focus */
}

/* opinion list table */

.opinion-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.opinion-table th,
.opinion-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.opinion-table th {
  background-color: #ff6800;
  color: white;
}

.opinion-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.opinion-table tr:hover {
  background-color: #ddd;
}

.opinion-table a {
  color: #007bff;
  text-decoration: none;
}

.opinion-table a:hover {
  text-decoration: underline;
}

/* Conversation show */

.conversation-item {
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.health-description {
  font-size: 16px !important;
  color: #333 !important;
  margin-bottom: 8px;
  font-weight: 500;
}

.conversation-date {
  font-size: 12px !important;
  color: #ff6800 !important;
}

.conversation-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Opinion Table css */

.opinion-table-container {
  max-height: 400px;
  /* Set the maximum height for the scrollable area */
  overflow-y: auto;
  /* Enable vertical scrolling */
  border: 1px solid #ddd;
  /* Optional: Add a border around the scrollable area */
  padding: 10px;
  background-color: #f9f9f9;
}

.opinion-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.report-image {
  max-width: 100px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.report-image:hover {
  transform: scale(1.05);
}

/* visa table css */

.table-container {
  max-width: 100%;
  /* Allow full width but scroll horizontally */
  overflow-x: auto;
  /* Enable horizontal scroll */
  overflow-y: hidden;
  /* Disable vertical scroll */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  white-space: nowrap;
  /* Prevent text from wrapping */
}

.visa-invitation-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.visa-invitation-table th,
.visa-invitation-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.visa-invitation-table th {
  background-color: #ff6800;
  color: #fff;
  position: sticky;
  top: 0;
}

.visa-invitation-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.visa-invitation-table tr:hover {
  background-color: #ddd;
}

.visa-invitation-table a {
  color: #ff6800;
  text-decoration: none;
}

.patient-dashboard-reports .css-b62m3t-container {
  z-index: 2 !important;
}

/* visa upload list */
.visa-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.visa-table th,
.visa-table td {
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #ddd;
}

.visa-table th {
  background-color: #ff6800;
  color: white;
  font-weight: 600;
}

.visa-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.visa-table tr:hover {
  background-color: #e9e9e9;
}

.visa-table td a {
  color: #ff6800;
  text-decoration: none;
  font-weight: 500;
}

.visa-table td a:hover {
  text-decoration: underline;
}

.details-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 1200px; /* Ensures table is wide enough for scrolling */
}

.details-table th,
.details-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.details-table th {
  background-color: #ff6800;
  color: #fff;
}

.details-table a {
  color: #ff6800;
  text-decoration: none;
}

.details-table a:hover {
  text-decoration: underline;
}

/* submision date and recive data css */

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.form-group .form-control {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 16px;
}

.button-group {
  display: flex;
  gap: 10px;
}

.button-group .btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-group .btn-primary {
  background-color: #007bff;
  color: #fff;
}

/* visa date list */

.patient-dashboard-reports {
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.visa-data-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.visa-data-item {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.visa-data-item p {
  margin: 8px 0;
  font-size: 16px;
  color: #555;
}

.visa-data-item strong {
  color: #000;
}

.report-preview {
  margin-bottom: 20px; /* Space between each report preview */
  border: 1px solid #ddd; /* Light border for separation */
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Hide overflow if content exceeds bounds */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  height: 150px; /* Set a fixed height for all previews */
  width: 100%; /* Ensure the container takes full width */
}

/* Style for PDF preview embedded */
.report-preview embed {
  width: 100%; /* Make sure PDF fills the container */
  height: 100%; /* Set height to match container height */
  object-fit: contain; /* Ensure PDF fits without stretching */
  border-radius: 8px 8px 0 0; /* Rounded corners for PDF embed */
}

/* Style for image preview */
.report-preview img {
  width: 100%; /* Ensure image fills the container */
  height: 150px; /* Set height to match container height */
  object-fit: contain; /* Maintain image aspect ratio without stretching */
  border-radius: 8px 8px 0 0; /* Rounded corners for images */
}

/* Optional: Hover effect for report previews */
.report-preview:hover {
  border-color: #ff6800; /* Change border color on hover */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
}

/* Optional: Adjust size for small screens */
@media (max-width: 600px) {
  .report-preview {
    height: 200px; /* Reduce height on smaller screens */
  }
}

.opinion-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  /* max-width: 1000px; */
  margin: 20px auto;
  padding: 0 15px;
}

.opinion-item {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
}

.opinion-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hospital-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 10px;
  flex-shrink: 0;
}

.hospital-name {
  font-weight: bold;
  /* font-size: 1.1rem; */
  color: #333;
  white-space: nowrap; /* Prevents text from wrapping to the next line */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds "..." when the text is too long */
  flex-grow: 1; /* Makes the text take up the remaining space in the container */
  max-width: calc(
    100% - 70px
  ); /* Prevents the name from overlapping the image */
}

.post-arrival-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(90deg, #34c3ff, #007bff);
  background-size: 200% 100%;
  padding: 10px 20px;
  border-radius: 5px;
  border: 2px solid transparent; /* Initial border */
  font-size: 16px;
  font-weight: bold;
  transition: background-position 0.5s ease, transform 0.2s ease,
    border-color 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.post-arrival-button:hover {
  background-position: 100% 0;
  transform: scale(1.05);
  /* border-color: #ff6800; 
  box-shadow: 0px 4px 10px #f88335;  */
}

.post-arrival-button .icon {
  margin-right: 8px;
  font-size: 20px;
}

.notification-bell {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-left: auto;
}

.bell-icon {
  font-size: 24px;
  color: #ff6800; /* Customize the color to match your theme */
  transition: transform 0.3s ease;
}

.bell-icon:hover {
  transform: scale(1.2);
}

.badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #ff4500; /* Red badge color */
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.reminder-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 80%;
  max-width: 600px;
  z-index: 1000;
  overflow: auto;
  max-height: 80vh;
}

/* h4 {
  margin-bottom: 20px;
  text-align: center;
} */

.reminder-popup .close-btn {
  border: none;
  font-size: 21px;
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  background: #000;
  padding: 6px;
}

.reminder-table {
  width: 100%;
  border-collapse: collapse;
}

.reminder-table th,
.reminder-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.reminder-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.reminder-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.reminder-table tr:hover {
  background-color: #f1f1f1;
}

.reminder-popup p {
  text-align: center;
  margin-top: 20px;
}

@media screen and (max-width: 880px) {
  .opinion-grid {
    grid-template-columns: auto;
  }
}
