.zoom-controls {
    margin-top: 10px;
  }
  
  .zoom-controls button {
    margin-right: 10px;
    padding: 8px 16px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .zoom-controls button:hover {
    background-color: #0056b3;
  }
  