.query-form-page {
  width: 500px;
  margin: 20px auto;
  padding: 20px;
  position: relative;
}

.medflick-visa-right {
  position: relative;
  width: 100%;
  height: auto;
  bottom: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 30px;
  margin-bottom: 20px;
  right: 0;
}

.medflick-visa-right h2 {
  color: #000;
  font-family: Helvetica Neue Medium;
  font-size: 35px;
  margin-bottom: 35px;
}

.medflick-visa-right .visa-form,
.medflick-visa-right .visa-form1 {
  width: 100%;
  gap: 10px;
  display: flex;
  background: none;
  padding: 0px;
  box-shadow: none;
}

.medflick-visa-right .visa-form-box {
  width: 100%;
  display: block;
}

.visa-form label {
  font-size: 14px;
  color: #000;
}

.visa-form input,
textarea {
  border: 1px solid #d2d4df;
  height: 47px;
  padding-left: 15px;
  width: 100%;
  font-size: 16px;
  border-radius: 8px;
  background-color: #fff;
  outline: none;
  text-align: left;
}

.visa-form select {
  border: 1px solid #d2d4df;
  height: 47px;
  padding-left: 15px;
  width: 100%;
  font-size: 16px;
  border-radius: 8px;
  background-color: #fff;
  outline: none;
  text-align: left;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper::placeholder {
  color: #000;
}

.medflick-visa-right .visa-form-box {
  width: 100%;
  display: block;
}

.medflick-visa-right .visa-form2 {
  width: 100%;
  gap: 10px;
  padding-bottom: 14px;
  display: flex;
}

.medflick-visa-right .medical-report-all {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 340px;
  margin-top: 0;
  text-align: left;
}

.medflick-visa-right .medical-report-file {
  background: #ff6800;
  color: #fff !important;
  border: 2px solid #ff6800;
  font-size: 16px;
  border-radius: 8px;
  padding: 10px;
  position: relative;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.medflick-visa-right .medical-report-all input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.visa-submit {
  background: #ff6800;
  color: #fff;
  border: 2px solid #ff6800;
  margin: 0 0 10px;
  font-size: 18px;
  border-radius: 8px;
  padding: 10px;
  position: relative;
  width: 100%;
}

.file__value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #7c7c7c;
  border: 1px solid #d8dee5;
}

.file__value:after {
  content: "X";
  cursor: pointer;
  background: #ff6800;
  color: #fff !important;
  padding: 5px;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  line-height: 13px;
}

.react-time-picker {
  width: 100%;
}

.react-time-picker__wrapper {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  background-color: #f9f9f9;
}

.react-time-picker__inputGroup {
  display: flex;
  gap: 5px;
}

.react-time-picker__inputGroup__input {
  border: none;
  background: none;
  padding: 0;
  font-size: 16px;
}

.react-time-picker__clear-button,
.react-time-picker__clock-button {
  display: none; /* Hides buttons */
}

.react-time-picker__inputGroup__divider {
  font-size: 16px;
  color: #333;
}

.react-time-picker__inputGroup__input:focus {
  outline: none;
}
