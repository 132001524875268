#login-section {
    background: #fff;
    display: block;
    flex-direction: column;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
}

.login-section {
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-section-left {
    width: 50%;
    margin: 0px;
    padding: 0px;
    position: relative;
    height: 100vh;
}

.login-section-left .login-logo {
    margin: 0px;
    padding: 30px 0px 0px;
    position: relative;
    text-align: center;
}

.login-section-left .login-logo img {
    width: auto;
    height: 60px;
}

.login-form {
    width: 450px;
    margin: 0px auto;
    padding: 4% 0% 0% 0%;
    position: relative;
    z-index: 1;
}

.login-form h2 {
    color: #000000;
    font-size: 30px;
    margin: 10px 0px 10px;
    padding: 0px;
    font-family: Inter Medium;
}

.login-form h3 {
    color: #97979A;
    font-size: 15px;
    margin: 0px 0px 10px;
    padding: 0px;
}

.login-form .login-form-box {
    width: 100%;
    padding: 15px 0px 0px;
    display: flex;
    gap: 15px;
}

.login-form .login-contact-form-box {
    display: block;
    width: 100%;
}

.login-form label {
    display: block;
    font-size: 14px;
    color: #3F464F;
    margin-bottom: 5px;
    font-family: Inter Medium;
}

.login-form input,
textarea {
    border: 1px solid #DADADA;
    height: 40px;
    padding-left: 15px;
    width: 100%;
    font-size: 16px;
    border-radius: 8px;
    background: #ffffff;
    outline: none;
    color: #444;
}


.login-form select {
    border: 1px solid #DADADA;
    height: 40px;
    padding-left: 15px;
    width: 100%;
    font-size: 16px;
    border-radius: 8px;
    background: #ffffff;
    outline: none;
    color: #444;
}

.login-section-right {
    background: #0F0F10;
    width: 50%;
    margin: 0px;
    padding: 4% 9%;
    position: relative;
    z-index: 1;
    height: 100vh;
}

.login-section-right:before {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: url(https://wgrowth.partners/wwpl/medflick_CRM/images/2024/05/bg.png) no-repeat;
    background-size: cover;
    z-index: -1;
}

.login-section-rightbox {
    height: 100%;
    border-radius: 16px;
    border: 1.028px solid rgba(255, 255, 255, 0.34);
    background: #FF6800;
    box-shadow: 0px 4px 118.3px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(0px);
    position: relative;
    z-index: 1;
}

.login-section-rightbox:before {
    content: "";
    position: absolute;
    top: 20px;
    right: 20px;
    width: 100%;
    height: 100%;
    background: url(https://wgrowth.partners/wwpl/medflick_CRM/images/2024/05/bg1.png) no-repeat;
    background-size: 30%;
    z-index: -1;
    background-position: top right;
}

.login-section-rightbox h2 {
    color: #ffffff;
    font-size: 32px;
    line-height: 40px;
    margin: 20px 40px 40px;
    padding: 0px;
    font-family: Inter Medium;
}

.error {
    color: red;
}

.login-section-rightbox img {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    border-radius: 0px 0px 16px 16px;
}

.login-form .submit-now {
    border-radius: 8px;
    background: linear-gradient(263deg, #FF6800 5.26%, #FF6800 104.79%);
    box-shadow: -4px 4px 9.8px 0px rgba(255, 255, 255, 0.29) inset, 0px 8px 19.2px -10px #FF6800;
    color: #ffffff;
    border: 2px solid #FF6800;
    font-size: 16px;
    padding: 10px;
    position: relative;
    width: 100%;
    margin: 0px 0 0px;
    font-family: Inter Medium;
}

@media screen and (max-width: 880px) {
    .login-section {
        flex-direction: column;
    }

    .login-section-left {
        width: 100%;
        height: 100%;
    }

    .login-section-left .login-logo {
        padding: 20px 0px 0px;
    }

    .login-form {
        width: 100%;
        padding: 40px 20px 50px;
    }
}