/* UserList.css */
.user-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.user-card {
  border: 1px solid #ff6800;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff8f0;
  margin-top: 10px;
}

.user-card p {
  margin: 5px 0;
}

.delete-button {
  background-color: #ff6800;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #e65c00;
}