@font-face {
  font-family: Roboto Regular;
  src: url(../fonts/Roboto-Regular.ttf);
}

@font-face {
  font-family: Roboto Medium;
  src: url(../fonts/Roboto-Medium.ttf);
}

@font-face {
  font-family: Roboto Light;
  src: url(../fonts/Roboto-Light.ttf);
}

@font-face {
  font-family: Roboto Bold;
  src: url(../fonts/Roboto-Bold.ttf);
}

/*-----------------------*/
html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0 auto;
  font-family: Roboto Regular;
  position: relative;
  font-weight: 400;
  overflow-x: hidden;
  color: #000;
  font-size: 16px;
  background: #fff;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  outline: none;
}

body a:hover,
body a:focus {
  text-decoration: none;
  color: #202833;
}

a:focus {
  outline: none;
  text-decoration: underline;
}

textarea:focus {
  outline: none;
}

input {
  outline: none !important;
}

button,
button:focus {
  outline: none;
}

a img {
  border: none;
}

img {
  border: 0;
  max-width: 100%;
  width: 100%;
}

p {
  padding: 0px;
  margin: 0px;
}

h1,
h2,
h3,
h4,
ul,
p {
  padding: 0px;
  margin: 0px;
}

textarea {
  resize: none;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #fff;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #fff;
}

input::-moz-placeholder {
  opacity: 1;
  outline: none;
}

textarea::-moz-placeholder {
  opacity: 1;
  outline: none;
}

.clr {
  clear: both;
  height: 0;
}

.latest-newsbox::placeholder {
  color: #666666;
  font-size: 15px;
}

.form-control:focus {
  box-shadow: none;
}

ul {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

a:hover {
  text-decoration: none;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.csn-ul {
  margin-left: 20px;
  text-align: justify;
}

.csn-ul li {
  position: relative;
  margin-bottom: 7px;
}

.csn-ul li:before {
  position: absolute;
  top: 9px;
  left: -19px;
  height: 8px;
  width: 8px;
  background: #014d49;
  border-radius: 100%;
  content: "";
}

/*===============header===============*/
#header-id {
  background: #0f0f10;
  height: auto;
  display: none;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  margin: 0px auto;
  top: 0px;
  border-bottom: 1px solid #cccccc;
  position: relative;
}

.header-inner {
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
}

.headerSecondry {
  align-items: center;
  background: none;
  display: flex;
  height: 60px;
  position: relative;
}

.logo-scrollup {
  display: block;
}

.logo-scrolldown {
  display: none;
}

.logo-d > img {
  width: 100%;
  position: relative;
}

.logo-d {
  width: 150px !important;
  transform: translateY(1px);
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.sticky .logo-d > img {
  max-width: 200px;
  transform: translateY(1px);
}

.sticky .logo1-d {
  max-width: 200px !important;
  transform: translateY(15px) !important;
  margin-left: 110px;
}

.logo-m > img {
  max-width: 130px;
  transform: translateY(-1px);
  position: absolute;
  display: none;
  left: 20px;
}

.sticky {
  position: fixed !important;
  top: 0;
  width: 100% !important;
  backdrop-filter: blur(40px);
  z-index: 999;
  /*box-shadow:1px 5px 10px rgba(0,0,0,0.2);*/
  background: #ffffff !important;
}

/*.sticky .headerTertiary{display:none;}*/
.sticky .headerSecondry {
  height: auto;
}

/*--Head END--*/

.wiki-mk {
  padding-left: 40px;
  padding-right: 40px;
}

.midbox-inner {
  margin: auto;
  max-width: 100%;
  width: 100%;
}

#medflick-mod {
  background: #fff;
  height: 100vh;
  margin: 0px;
  padding: 0px 0px 0px;
  position: relative;
  overflow: hidden;
}

#head-top {
  background: #fff;
  margin: 0px;
  padding: 0px 0px 0px;
  position: relative;
  overflow: hidden;
}

.head-top {
  display: flex;
  gap: 0px;
  align-items: center;
  background: linear-gradient(to right, #0f0f10 16%, #fbfbfb 0%);
  margin: 0px;
  padding: 0px;
  position: relative;
  z-index: 1;
}

.head-top-left {
  width: 16%;
  background: #0f0f10;
  border-right: 0px solid #e1e4ed;
  margin: 0%;
  padding: 20px 20px 20px 40px;
  position: relative;
  height: 70px;
}

.head-top-left img {
  width: auto;
  height: 38px;
}

.head-top-right {
  width: 84%;
  border-bottom: 1px solid #e8e8e8;
  padding: 20px 20px 20px 20px;
  position: relative;
  height: 70px;
  display: flex;
  align-items: center;
  gap: 30px;
  height: 70px;
}

.head-top-right h1 {
  color: #000;
  font-size: 16px;
}

.head-top-right a {
  color: #000;
  font-size: 14px;
}

.medflick-mod {
  display: flex;
  height: 100%;
  gap: 0px;
  background: linear-gradient(to right, #0f0f10 15%, #f5f5f5 0%);
  margin: 0px;
  padding: 0px;
  position: relative;
  z-index: 1;
}

/*.medflick-mod::before {content: "";position: absolute;bottom: 0px;right: 0px;width: 100%;height: 100%;background: url(../images/2024/01/bg.png) no-repeat;background-size: cover;background-position: bottom center;z-index: -1;}*/

.medflick-mod-left {
  width: 16%;
  background: #0f0f10;
  border-right: 0px solid #e1e4ed;
  margin-left: 0px;
  padding: 20px 20px 20px 20px;
  position: relative;
}

.medflick-nav-left ul {
  display: block;
  margin: 0px;
  padding: 0px;
  gap: 14px;
}

.medflick-nav-left li {
  padding: 0px;
  list-style: none;
  margin: 0px 0px 10px;
  padding-left: 0px;
  position: relative;
}

.medflick-nav-left li img {
  width: auto;
  height: 20px;
  padding-right: 7px;
}

.medflick-nav-left li .active {
  color: #fff;
  border-radius: 8px;
  border: 1px solid #231310;
  background: #ff6800;
  box-shadow: 0px 6px 29px 0px rgba(89, 108, 148, 0.07),
    0px 0.751px 3.631px 0px rgba(89, 108, 148, 0.04);
}

.medflick-nav-left li::before {
  background: none;
}

.medflick-nav-left li a {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #7a7a7a;
  border: 1px solid #231310;
  margin: 0px;
  padding: 12px 15px;
  position: relative;
}

.medflick-nav-left li a:hover {
  color: #fff;
  border-radius: 8px;
  border: 1px solid #231310;
  background: #ff6800;
  box-shadow: 0px 6px 29px 0px rgba(89, 108, 148, 0.07),
    0px 0.751px 3.631px 0px rgba(89, 108, 148, 0.04);
}

.medflick-nav-left li a:hover img {
  filter: brightness(0) invert(1);
}

.medflick-nav-left li.active a {
  color: #fff;
  border-radius: 8px;
  border: 1px solid #231310;
  background: #ff6800;
  box-shadow: 0px 6px 29px 0px rgba(89, 108, 148, 0.07),
    0px 0.751px 3.631px 0px rgba(89, 108, 148, 0.04);
}

.medflick-nav-left li.active a img {
  filter: brightness(0) invert(1);
}

.mod-report-nav a {
  color: #fff !important;
  border-radius: 8px;
  border: 1px solid #231310;
  background: #ff6800;
  box-shadow: 0px 6px 29px 0px rgba(89, 108, 148, 0.07),
    0px 0.751px 3.631px 0px rgba(89, 108, 148, 0.04);
}

.medflick-nav-bottom {
  width: auto;
  display: flex;
  margin: 0px;
  padding: 0px;
  align-items: flex-end;
  position: absolute;
  bottom: 85px;
  justify-content: left;
  left: 20px;
}

.medflick-box-bottom {
  width: 100%;
  display: block;
  margin: 0px;
  padding: 0px;
}

.medflick-nav-bottom p {
  color: #ffffff;
  font-size: 15px;
  margin: 0px;
  padding: 70px 10px 10px;
}

.medflick-nav-bottom ul {
  display: block;
  margin: 0px;
  padding: 0px;
  gap: 0px;
}

.medflick-nav-bottom li {
  padding: 0px;
  list-style: none;
  margin: 0px;
  padding-left: 0px;
  position: relative;
}

.medflick-nav-bottom li img {
  width: auto;
  height: 20px;
  padding-right: 4px;
}

.medflick-nav-bottom li::before {
  background: none;
}

.medflick-nav-bottom li a {
  font-size: 14px;
  color: #7a7a7a;
  display: block;
  margin: 0px;
  padding: 9px 0px 9px 15px;
  position: relative;
}

.medflick-nav-bottom li a:hover {
  color: #ff6800;
}

.medflick-mod-right {
  width: 84%;
  padding: 20px 40px 20px 20px;
  position: relative;
}

.medflick-mod-mid {
  width: 50%;
  padding: 20px 20px;
  position: relative;
}

.medflick-home-box {
  padding: 20px 0px 0px 20px;
}

.medflick-home-box ul {
  display: flex;
  gap: 30px;
  margin: 0px;
  padding: 0px;
  position: relative;
  z-index: 1;
}

.medflick-home-box li {
  width: 33.33%;
  list-style: none;
  margin: 0px;
  padding: 0px 0px 20px 20px;
  position: relative;
  border-radius: 20px;
  border: 1px solid #d3d8e0;
  background: #fff;
  box-shadow: 0px -4px 0px 0px #fbfbfb inset;
  z-index: -1;
  overflow: hidden;
}

.medflick-home-box li::after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  /* background: url(../images/2024/01/box-bg.png) no-repeat; */
  background-size: 70%;
  background-position: bottom right;
  z-index: -1;
}

.medflick-home-box li h3 {
  font-size: 24px;
  color: #000;
  font-family: Roboto Medium;
  margin: 0px 0px 15px;
  padding: 0px;
  position: relative;
}

.medflick-home-box li p {
  font-size: 14px;
  color: #8e95a2;
  margin: 0px 0px 20px;
  padding: 0px;
  position: relative;
}

.medflick-home-box li a {
  width: max-content;
  font-size: 14px;
  color: #ffffff;
  border-radius: 6px;
  background: #da2128;
  display: flex;
  gap: 15px;
  align-items: center;
  margin: 0px;
  padding: 7px 15px;
  position: relative;
}

.medflick-home-box li a img {
  width: auto;
  height: 12px;
  padding-right: 7px;
  filter: brightness(0) invert(1);
}

.medflick-home-box li a:hover {
  border-radius: 6px;
  border: 1px solid #f25656;
  background: #ffecec;
  box-shadow: 0px 4px 4.5px -3px rgba(0, 0, 0, 0.25);
  color: #f25656;
}

.medflick-home-box li a:hover img {
  filter: none;
}

.medflick-updates-right {
  width: 25%;
  padding: 20px 7% 20px 10px;
  position: relative;
}

/*---- home END ----*/

/*---- File MOD Report ----*/

.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #ff6800 #ffffff;
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.scrollbar::-webkit-scrollbar-track {
  background-clip: content-box;
  border: 1px solid transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #ff6800;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff;
}

.scrollbar::-webkit-scrollbar-corner,
.scrollbar::-webkit-scrollbar-track {
  background-color: #ffffff;
}

/* The below styles are for display purposes only */
[class^="scrollbar"] {
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
  margin-right: -10px;
  padding-bottom: 40px;
}

.scrollbar-1 {
  scrollbar-width: thin;
  scrollbar-color: #ff6800 #ffe6d5;
}

.scrollbar-1::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.scrollbar-1::-webkit-scrollbar-track {
  background-clip: content-box;
  border: 1px solid transparent;
}

.scrollbar-1::-webkit-scrollbar-thumb {
  background-color: #ff6800;
}

.scrollbar-1::-webkit-scrollbar-thumb:hover {
  background-color: #ffe6d5;
}

.scrollbar-1::-webkit-scrollbar-corner,
.scrollbar-1::-webkit-scrollbar-track {
  background-color: #ffe6d5;
}

/* The below styles are for display purposes only */
[class^="scrollbar-1"] {
  height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
  margin-right: -10px;
}

.medflick-home-box {
  margin: 0px 0px 20px;
  padding: 0px;
  position: relative;
}

.medflick-home-box ul {
  display: flex;
  gap: 20px;
  margin: 20px 0px 0px;
  padding: 0px;
  position: relative;
}

.medflick-home-box li {
  width: 33.33%;
  margin: 0px;
  padding: 20px;
  position: relative;
  border-radius: 12px;
  border: 1px solid #d3d8e0;
  background: #fff;
  box-shadow: 0px -4px 0px 0px #fbfbfb inset;
}

.total-leads-box {
  display: flex;
  gap: 50px;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0px 0px 0px;
  padding: 0px 0px 10px;
  position: relative;
}

.leads-text {
  display: block;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.leads-text h2 {
  font-size: 28px;
  color: #000;
  font-weight: bold;
  text-align: center;
  margin: 0px 0px 0px;
  padding: 0px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
}

.leads-text h2 span {
  border-radius: 4px;
  background: #ff6800;
  font-size: 12px;
  color: #fff;
  padding: 3px 10px;
}

.total-leads-box h4 {
  font-size: 14px;
  color: #626262;
  margin: 0px 0px 0px;
  padding: 0px;
  position: relative;
}

.leads-day-box {
  color: #8e8e8e;
  font-size: 14px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.leads-day-box span {
  width: 14%;
}

.total-leads-box select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #ffffff;
  background-image: none;
  flex: 1;
  padding: 0px;
  color: #bdbdbd;
  cursor: pointer;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}

.total-leads-box select::-ms-expand {
  display: none;
}

.total-leads-box .select {
  position: relative;
  display: flex;
  width: 100px;
  height: 20px;
  line-height: 20px;
  background: #ffffff;
  border: 0px solid #dadada;
  overflow: hidden;
  border-radius: 8px;
}

.total-leads-box .select::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 20px;
  background: url(../images/2024/01/arrow-icon1.png) no-repeat;
  background-size: 15px;
  top: 7px;
  right: 0;
  padding: 0 0.5em;
  cursor: pointer;
  pointer-events: none;
  transition: 0.25s all ease;
}

.total-leads-box .select:hover::after {
  color: #444444;
}

.leads-search-doc {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0px;
  padding: 0px;
  position: relative;
  justify-content: space-between;
}

.leads-search-doc h2 {
  font-size: 16px;
  color: #000;
}

.leads-crm-search {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0px;
  padding: 0px;
  position: relative;
  justify-content: right;
  padding-left: 4%;
}

.leads-crm-search-box {
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.leads-crm-search-box::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: url(../images/2024/01/search-icon.png) no-repeat;
  background-size: 100%;
  background-position: left top;
  left: 10px;
  top: 10px;
  z-index: 1;
}

.filters-box {
  color: #838383;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #edf0f2;
  background: #fff;
  box-shadow: 0px 3px 3.3px -2px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0px;
  padding: 8px 20px;
  position: relative;
  cursor: pointer;
}

.filters-box img {
  width: auto;
  height: 20px;
  margin-left: -10px;
  margin-right: 14px;
}

#target {
  border: 1px solid #edf0f2;
  background: #fff;
  box-shadow: 0px 3px 3.3px -2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: 240px;
  height: 180px;
  padding: 15px;
  display: none;
  position: absolute;
  right: 0px;
  top: 40px;
  z-index: 666;
}

.Hide {
  display: none;
}

#target .ding {
  margin-bottom: 10px;
}

.leads-crm-search input::placeholder {
  color: rgba(77, 81, 110, 0.5);
  font-size: 16px;
}

.leads-crm-search input:focus {
  border: 1px solid #dfdfdf;
}

.leads-crm-search input {
  height: 40px;
  padding-left: 45px;
  width: 100%;
  font-size: 16px;
  border-radius: 8px 8px;
  background: #fff;
  border: 1px solid #dfdfdf;
  outline: none;
  position: relative;
}

.leads-patient-box {
  border-radius: 8px;
  border: 1px solid #efefef;
  background: #fff;
  box-shadow: 0px 4px 6px -3px rgba(0, 0, 0, 0.05);
  margin: 20px 0px 0px;
  padding: 20px;
  position: relative;
}

.leads-patient-head {
  display: flex;
  border-bottom: 1.4px solid #e9edf1;
  margin: 0px 0px 10px;
  padding: 0px 0px 10px;
  color: #8e8e8e;
  font-size: 14px;
}

.leads-patient-head-box1 {
  width: 16%;
  margin: 0px;
  padding: 4px 5px;
  border-right: 1px solid #efefef;
  position: relative;
}

.leads-patient-head-box2 {
  width: 10%;
  text-align: center;
  margin: 0px;
  padding: 4px 5px;
  border-right: 1px solid #efefef;
  position: relative;
}

.leads-patient-head-box3 {
  width: 8%;
  text-align: center;
  margin: 0px;
  padding: 4px 5px;
  border-right: 1px solid #efefef;
  position: relative;
}

.leads-patient-head-box4 {
  width: 15%;
  text-align: center;
  margin: 0px;
  padding: 4px 5px;
  border-right: 1px solid #efefef;
  position: relative;
}

.leads-patient-head-box5 {
  width: 15%;
  text-align: center;
  margin: 0px;
  padding: 4px 5px;
  border-right: 1px solid #efefef;
  position: relative;
}

.leads-patient-head-box6 {
  width: 15%;
  text-align: center;
  margin: 0px;
  padding: 4px 5px;
  border-right: 1px solid #efefef;
  position: relative;
}

.leads-patient-head-box7 {
  width: 11%;
  text-align: center;
  margin: 0px;
  padding: 4px 5px;
  border-right: 1px solid #efefef;
  position: relative;
}

.leads-patient-head-box8 {
  width: 10%;
  text-align: center;
  margin: 0px 4px 0px;
  padding: 4px 5px;
  position: relative;
}

.leads-patient-doc-crm {
  display: flex;
  font-size: 14px;
  align-items: center;
  margin: 0px 0px 0px;
  padding: 0px 0px 0px;
}

.leads-patient-name {
  border-right: 0px solid #efefef;
  color: #737373;
  display: flex;
  align-items: center;
  width: 16%;
  margin: 0px;
  padding: 8px 5px;
  position: relative;
}

.leads-patient-country {
  border-radius: 2px;
  border: 0.5px solid rgba(103, 103, 103, 0.07);
  background: rgba(122, 122, 122, 0.03);
  border-right: 1px solid #efefef;
  color: #737373;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 10%;
  margin: 0px;
  padding: 8px 5px;
  position: relative;
}

.leads-patient-country img {
  width: auto;
  height: 15px;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.leads-patient-source {
  border-right: 1px solid #efefef;
  color: #737373;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8%;
  margin: 0px;
  padding: 8px 5px;
  position: relative;
}

.leads-patient-source img {
  width: auto;
  height: 18px;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.leads-patient-source .fa-whatsapp {
  background: #34ca00;
  color: #fff;
  border-radius: 4px;
  padding: 6px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leads-patient-source .fa-facebook-f {
  background: #056de6;
  color: #fff;
  border-radius: 4px;
  padding: 6px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leads-patient-source .fa-x-twitter {
  background: #383535;
  color: #fff;
  border-radius: 4px;
  padding: 6px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leads-patient-source .fa-linkedin-in {
  background: #0274b3;
  color: #fff;
  border-radius: 4px;
  padding: 6px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leads-patient-source .fa-instagram {
  background: linear-gradient(
    115deg,
    rgb(249, 206, 52),
    rgb(238, 42, 123),
    rgb(98, 40, 215)
  );
  color: #fff;
  border-radius: 4px;
  padding: 6px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leads-patient-source .fa-website {
  background: #ff6800;
  color: #fff;
  border-radius: 4px;
  padding: 6px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leads-patient-source .fa-youtube {
  background: #ea0a05;
  color: #fff;
  border-radius: 4px;
  padding: 6px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}

.css-hra0ve {
  border-radius: 50%;
  background: repeating-conic-gradient(
    rgb(255, 105, 0) 0deg,
    rgb(255, 105, 0) 18deg,
    transparent 18deg,
    transparent 30deg
  );
  width: 3.5rem;
  height: 3.5rem;
  -webkit-animation: fa-spin 15s infinite linear;
  animation: fa-spin 15s infinite linear;
}

.leads-patient-speciality {
  border-right: 1px solid #efefef;
  color: #737373;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  margin: 0px;
  padding: 8px 5px;
  position: relative;
}

.leads-patient-report-status {
  border-right: 1px solid #efefef;
  color: #f77;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  margin: 0px;
  padding: 8px 5px;
  position: relative;
}

.leads-patient-referred {
  border-right: 1px solid #efefef;
  color: #007efc;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  margin: 0px;
  padding: 8px 5px;
  position: relative;
}

.leads-patient-status {
  border-right: 1px solid #efefef;
  color: #007efc;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 11%;
  margin: 0px;
  padding: 8px 5px;
  position: relative;
}

.leads-patient-view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  margin: 0px;
  padding: 8px 5px;
  position: relative;
}

.leads-patient-view .view {
  font-size: 14px;
  color: #fff;
  border-radius: 4px;
  border: 0.5px solid rgba(255, 255, 255, 0.56);
  background: #ff6800;
  margin: 0px;
  padding: 3px 14px;
}

/*---- patient-dashboard ----*/

.patient-dashboard-doc {
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  background: #fff;
  box-shadow: 0px 8px 14.4px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  margin: 0px;
  padding: 10px;
  position: relative;
}

.patient-dashboard-box {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.patient-img-box {
  width: 60px;
  height: auto;
}

.patient-img-box img {
  border-radius: 100%;
  border-radius: 100%;
  position: absolute;
  top: 2px;
  left: 2.4px;
  width: auto;
  height: 52px;
}

.patient-doc-box h2 {
  font-size: 22px;
  color: #000;
  margin: 0px;
  padding: 0px 0px 5px;
  position: relative;
}

.patient-doc-box h2 span {
  font-size: 16px;
  color: #525e6c;
}

.patient-doc-data ul {
  display: flex;
  gap: 10px;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.patient-doc-data li {
  font-size: 14px;
  color: #525e6c;
  list-style: none;
  margin: 0px;
  padding: 0px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
}

.patient-doc-data li img {
  width: auto;
  height: 10px;
}

.patient-dashboard-query {
  width: 20%;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.patient-dashboard-query h3 {
  font-size: 18px;
  color: #000;
  margin: 0px;
  padding: 0px 0px 10px;
  position: relative;
}

.patient-dashboard-query p {
  font-size: 13px;
  color: #525e6c;
  margin: 0px;
  padding: 0px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 7px;
}

.patient-dashboard-query span {
  width: 5px;
  height: 5px;
  background: #525e6c;
  border-radius: 100%;
  display: inline-block;
}

.patient-dashboard-reply {
  width: 20%;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.patient-dashboard-reply h3 {
  font-size: 18px;
  color: #000;
  margin: 0px;
  padding: 0px 0px 10px;
  position: relative;
}

.patient-dashboard-reply p {
  font-size: 13px;
  color: #525e6c;
  margin: 0px;
  padding: 0px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 7px;
}

.patient-dashboard-reply span {
  width: 5px;
  height: 5px;
  background: #525e6c;
  border-radius: 100%;
  display: inline-block;
}

.patient-dashboard-msg {
  width: 10%;
  margin: 0px;
  padding: 10px;
  position: relative;
}

.patient-dashboard-msg a {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border-radius: 8px;
  border: 1px solid #ff6800;
  background: #ff6800;
  box-shadow: 0px 4px 12.8px -3px rgba(0, 0, 0, 0.31),
    0px 0px 8.5px 0px rgba(255, 255, 255, 0.43) inset;
  margin: 0px;
  padding: 7px 4px;
}

.patient-dashboard-msg a:hover {
  background: #ffffff;
  color: #ff6800;
}

.patient-dashboard-casebox {
  display: flex;
  gap: 25px;
  margin: 20px 0px 0px;
  padding: 0px;
  position: relative;
}

.patient-dashboard-case-left {
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  background: #fff;
  box-shadow: 0px 8px 14.4px -14px rgba(0, 0, 0, 0.25);
  width: 25%;
  margin: 0px;
  padding: 15px;
  position: relative;
}

.patient-dashboard-case-left h2 {
  display: flex;
  color: #15192c;
  font-size: 18px;
  border-bottom: 1.4px solid #e9edf1;
  padding-bottom: 10px;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
}

.patient-dashboard-case-left h2 a {
  width: max-content;
  display: flex;
  gap: 5px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border-radius: 8px;
  border: 1px solid #ff6800;
  background: #ff6800;
  box-shadow: 0px 4px 12.8px -3px rgba(0, 0, 0, 0.31),
    0px 0px 8.5px 0px rgba(255, 255, 255, 0.43) inset;
  margin: 0px;
  padding: 7px 8px;
}

.patient-dashboard-case-left h2 a:hover {
  background: #ffffff;
  color: #ff6800;
}

.patient-dashboard-case-left a img {
  width: auto;
  height: 16px;
  filter: brightness(0) invert(1);
}

.patient-dashboard-case-left a:hover img {
  filter: initial;
}

.patient-dashboard-case-left .stage-text {
  font-size: 14px;
  color: #000;
}

.patient-dashboard-case-left .stage-text span {
  color: #ff6800;
}

.patient-dashboard-case-left ul {
  margin: 0px;
  padding: 0px;
  position: relative;
  z-index: 1;
}

.patient-dashboard-case-left ul::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 7px;
  width: 1px;
  height: 100%;
  background: #d9d9d9;
  z-index: -1;
}

.patient-dashboard-case-left ul li {
  list-style: none;
  margin: 10px 0px 30px;
  padding-left: 35px;
  position: relative;
}

.patient-dashboard-case-left li p {
  color: #5b6377;
  font-size: 14px;
  padding: 7px 0px 7px;
}

.patient-dashboard-case-left li .up-datalist {
  display: flex;
  align-items: center;
  gap: 7px;
  color: #8e95a2;
  font-size: 11px;
}

.patient-dashboard-case-left li .up-datalist span {
  background: #8e95a2;
  width: 5px;
  height: 5px;
  border-radius: 100%;
}

.patient-dashboard-case-left li .up-datalist h5 {
  color: #8e95a2;
  font-size: 11px;
  margin: 0px;
  padding: 0px;
}

.patient-dashboard-case-left li .updates-img {
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 0px;
  width: 15px;
  height: 15px;
  padding: 5px;
  border-radius: 50px;
  background: grey;
  box-shadow: 0px 1px 0.9px 0px rgba(0, 0, 0, 0.07);
}

.updates-frame {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 7px;
  color: #5b6377;
  font-size: 12px;
}

.updates-frame img {
  width: auto;
  height: 12px;
}

.patient-dashboard-case-right {
  width: 76%;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.patient-dashboard-reports {
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  background: #fff;
  box-shadow: 0px 8px 14.4px -14px rgba(0, 0, 0, 0.25);
  margin: 0px;
  padding: 15px;
  position: relative;
}

/* .patient-dashboard-reports::before {
  content: "✔"; 
  position: absolute;
  top: -10px; 
  left: -10px; 
  background-color: #28a745; 
  color: white; 
  font-size: 14px; 
  width: 20px; 
  height: 20px;
  line-height: 20px; 
  text-align: center; 
  border-radius: 50%; 
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
} */

.patient-dashboard-reports h2 {
  display: flex;
  color: #15192c;
  font-size: 18px;
  border-bottom: 1.4px solid #e9edf1;
  padding-bottom: 10px;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

.patient-dashboard-reports h2 a {
  width: max-content;
  display: flex;
  gap: 5px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border-radius: 8px;
  /* border: 1px solid #ff6800; */
  background: #ff6800;
  box-shadow: 0px 4px 12.8px -3px rgba(0, 0, 0, 0.31),
    0px 0px 8.5px 0px rgba(255, 255, 255, 0.43) inset;
  margin: 0px;
  padding: 7px 8px;
  font-weight: 400;
}

/* .patient-dashboard-reports h2 a:hover {
  background: #ffffff;
  color: #ff6800;
} */

.patient-dashboard-reports h2 img {
  width: auto;
  height: 16px;
  filter: brightness(0) invert(1);
}

/* 
.patient-dashboard-reports a:hover img {
  filter: initial;
} */

.dashboard-upload-reports {
  display: flex;
  align-items: flex-start;
  gap: 0px;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.dashboard-upload-reportsbox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.dashboard-upload-reports-file {
  width: 30%;
  gap: 25px;
  margin: 0px;
  padding: 0px;
  position: relative;
  z-index: 1;
  height: 160px;
}

.dashboard-upload-reports-file:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  /* background: url(../images/2024/01/bg.png) no-repeat; */
  background-size: 100%;
  z-index: -1;
}

.upload-reports-text {
  width: 90%;
  border-radius: 11px;
  opacity: 0.8;
  position: absolute;
  bottom: 20px;
  left: 10px;
  background: #fff;
  padding: 8px;
}

.upload-reports-text h4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #000;
  margin: 0px;
  padding: 0px 0px 5px;
  position: relative;
}

.upload-reports-text h4 a:hover {
  background: #ffffff;
  color: #ff6800;
}

.upload-reports-text a img {
  width: auto;
  height: 10px;
}

.upload-reports-text p {
  font-size: 10px;
  color: #525e6c;
  margin: 0px;
  padding: 0px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 7px;
}

.upload-reports-text span {
  width: 5px;
  height: 5px;
  background: #525e6c;
  border-radius: 100%;
  display: inline-block;
}

.dashboard-upload-file-box {
  width: 30%;
  display: block;
  margin: 20px 0px 0px;
  padding: 0px;
  position: relative;
}

.dashboard-upload-file-box .file-input img {
  width: auto;
  height: 38px;
  margin: 20px auto;
  display: flex;
}

.dashboard-upload-file-box .file-input {
  display: inline-block;
  text-align: left;
  background: #ffffff;
  border: 1px solid #dee2db;
  padding: 6px 16px 20px;
  width: 100%;
  margin-top: 10px;
  position: relative;
  border-radius: 8px;
}

.dashboard-upload-file-box .file-input > [type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.dashboard-upload-file-box .file-input .button {
  display: inline-block;
  color: #46bb00;
  cursor: pointer;
  padding: 0px 8px;
  width: max-content;
  position: relative;
}

.dashboard-upload-file-box .file-input:hover > .button {
  background: #03426d;
  color: white;
}

.dashboard-upload-file-box .file-input > .label {
  color: #303030;
  white-space: initial;
  text-align: center;
  width: 100%;
  display: block;
  font-size: 14px;
}

.dashboard-upload-file-box .file-input.-chosen > .label {
  opacity: 1;
}

.patient-dashboard-opinion {
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  background: #fff;
  box-shadow: 0px 8px 14.4px -14px rgba(0, 0, 0, 0.25);
  margin: 20px 0px 20px;
  padding: 15px;
  position: relative;
}

.patient-dashboard-opinion h2 {
  display: flex;
  color: #15192c;
  font-size: 18px;
  border-bottom: 1.4px solid #e9edf1;
  padding-bottom: 10px;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

.patient-dashboard-opinion h2 a {
  width: max-content;
  display: flex;
  gap: 5px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border-radius: 8px;
  /* border: 1px solid #ff6800; */
  background: #ff6800;
  box-shadow: 0px 4px 12.8px -3px rgba(0, 0, 0, 0.31),
    0px 0px 8.5px 0px rgba(255, 255, 255, 0.43) inset;
  margin: 0px;
  padding: 7px 8px;
  font-weight: 400;
}

/* .patient-dashboard-opinion h2 a:hover {
  background: #ffffff;
  color: #ff6800;
} */

.patient-dashboard-opinion h2 img {
  width: auto;
  height: 16px;
  filter: brightness(0) invert(1);
}

.patient-dashboard-opinion a:hover img {
  filter: initial;
}

.patient-dashboard-opinion p {
  color: #525e6c;
  font-size: 14px;
}

.pdf-file {
  width: max-content;
  border-radius: 8px;
  border: 1px solid #ff6800;
  background: #ffebde;
  color: #ff6800;
  font-size: 14px;
  padding: 4px 10px;
  margin: 10px 0px;
}

.patient-dashboard-passport {
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  background: #fff;
  box-shadow: 0px 8px 14.4px -14px rgba(0, 0, 0, 0.25);
  margin: 0px 0px 0px;
  padding: 15px;
  position: relative;
}

.patient-dashboard-passport h2 {
  display: flex;
  color: #15192c;
  font-size: 18px;
  border-bottom: 1.4px solid #e9edf1;
  padding-bottom: 10px;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
}

.patient-dashboard-passport h2 a {
  width: max-content;
  display: flex;
  gap: 5px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border-radius: 8px;
  border: 1px solid #ff6800;
  background: #ff6800;
  box-shadow: 0px 4px 12.8px -3px rgba(0, 0, 0, 0.31),
    0px 0px 8.5px 0px rgba(255, 255, 255, 0.43) inset;
  margin: 0px;
  padding: 7px 8px;
}

.patient-dashboard-passport h2 a:hover {
  background: #ffffff;
  color: #ff6800;
}

.patient-dashboard-passport h2 img {
  width: auto;
  height: 16px;
  filter: brightness(0) invert(1);
}

.patient-dashboard-passport a:hover img {
  filter: initial;
}

.patient-dashboard-passport .dashboard-upload-file-box {
  width: 100%;
}

.patient-dashboard-passport .file-input img {
  width: auto;
  height: 52px;
  margin: 30px auto;
  display: flex;
}

.patient-dashboard-passport .file-input > .label {
  color: #525e6c;
  text-align: center;
  width: 60%;
  font-size: 14px;
  margin: 0px auto;
}

/*---- patient-dashboard END ----*/

/*---- footer MOB ----*/
.footer-bottom-mob {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  border-radius: 24px 24px 0px 0px;
  border: 1px solid #e0e0e0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.22) 0%, #fff 100%);
  box-shadow: 0px -10px 57.3px -10px rgba(0, 0, 0, 0.88);
  backdrop-filter: blur(26.200000762939453px);
  display: none;
}

.navigation-mobile-link {
  height: 60px;
  color: #1e1e2f;
  text-align: center;
  justify-content: center;
  flex-grow: 1 !important;
  flex-direction: column !important;
  display: flex;
  text-decoration: none;
  border-right: 0px solid #ffffff;
}

.navigation-mobile-link .mx-auto {
  margin-left: auto !important;
  width: auto;
  height: 20px;
  margin-right: auto !important;
}

.f-12 {
  font-size: 12px;
  padding-top: 5px;
}

/*---- footer MOB END ----*/

@media screen and (max-width: 880px) {
  #header-id {
    display: block;
  }

  .logo-m > img {
    width: auto;
    height: 40px;
    display: block;
    padding: 0px 0;
    transform: translateY(-20px);
  }

  .wiki-mk {
    padding-left: 20px;
    padding-right: 20px;
  }

  .patient-dashboard-reports h2 {
    display: inherit;
  }

  .patient-dashboard-reports h2 a {
    width: 100%;
    margin: 10px 0px;
  }

  .patient-dashboard-opinion h2 {
    display: inherit;
  }
  .patient-dashboard-opinion h2 a {
    width: 100%;
    margin: 10px 0px;
  }

  .mob-head-button {
    display: flex;
    margin: 0px;
    gap: 10px;
    right: 15px;
    top: 14px;
    position: absolute;
    align-items: center;
    justify-content: center;
  }

  .footer-bottom-mob {
    display: flex;
  }

  .cta-bar {
    bottom: 70px;
  }

  .medflick-mod {
    flex-direction: column;
  }

  .medflick-mod-mid {
    width: 100%;
    padding: 15px;
    position: relative;
  }

  .medflick-mod-mid .file-mod-report {
    margin-bottom: 0px;
  }

  .medflick-updates-right {
    width: 100%;
    padding: 15px;
    position: relative;
    margin-bottom: 110px;
  }

  /*---- home----*/
  #medflick-mod {
    height: 100%;
  }

  .head-top-left {
    display: none;
  }

  .head-top-right {
    background: #fbfbfb;
    width: 100%;
    justify-content: space-between;
    padding: 15px;
    height: auto;
  }

  .medflick-mod-left {
    display: none;
  }

  .medflick-mod-right {
    background: #fbfbfb;
    width: 100%;
    padding: 0px 15px 15px;
  }

  .medflick-home-box {
    padding: 20px 0px 20px;
  }

  .leads-search-doc {
    flex-direction: column;
  }

  .medflick-home-box ul {
    flex-direction: column;
  }

  .medflick-home-box li {
    width: 100%;
  }

  .medflick-home-box li h3 {
    font-size: 20px;
  }

  .leads-patient-head {
    display: none;
  }

  .leads-patient-doc-crm {
    flex-wrap: wrap;
    gap: 7px;
    margin-bottom: 15px;
    border-bottom: 1.4px solid #e9edf1;
    padding: 0px 0px 15px;
  }

  .leads-patient-name {
    width: 100%;
    border-right: 0px;
    padding: 5px;
  }

  .leads-patient-country {
    width: 25%;
    padding: 5px;
  }

  .leads-patient-source {
    width: 15%;
    border-right: 0px;
    padding: 5px;
    justify-content: left;
  }

  .leads-patient-speciality {
    width: 50%;
    border-right: 0px;
    padding: 5px;
    justify-content: left;
  }

  .leads-patient-report-status {
    width: 43%;
    padding: 5px;
    justify-content: left;
  }

  .leads-patient-referred {
    width: 43%;
    border-right: 0px;
    padding: 5px;
    justify-content: left;
  }

  .leads-patient-status {
    width: 43%;
    padding: 5px;
    justify-content: left;
  }

  .leads-patient-view {
    width: 43%;
    padding: 5px;
    justify-content: left;
  }

  /*---- home END ----*/

  /*---- patient-dashboard ----*/
  .patient-dashboard-doc {
    flex-direction: column;
  }

  .patient-doc-data ul {
    flex-wrap: wrap;
  }

  .patient-doc-data li {
    width: 48%;
    font-size: 12px;
  }

  .patient-dashboard-box {
    width: 100%;
    align-items: flex-start;
  }

  .patient-dashboard-query {
    width: 100%;
    margin-top: 10px;
  }

  .patient-dashboard-query h3 {
    font-size: 16px;
    padding: 10px 0px 5px;
  }

  .patient-dashboard-reply {
    width: 100%;
    margin-top: 10px;
  }

  .patient-dashboard-msg {
    width: 100%;
  }

  .patient-dashboard-reply h3 {
    font-size: 16px;
    padding: 0px 0px 5px;
  }

  .patient-dashboard-casebox {
    flex-direction: column;
  }

  .patient-dashboard-case-left {
    width: 100%;
  }

  .patient-dashboard-case-right {
    width: 100%;
  }

  .patient-doc-box h2 {
    font-size: 18px;
  }

  .dashboard-upload-reports {
    flex-direction: column;
  }

  .dashboard-upload-reports-file {
    width: 100%;
  }

  .dashboard-upload-file-box {
    width: 100%;
  }

  .patient-dashboard-passport .file-input > .label {
    width: 100%;
    font-size: 12px;
  }

  /*---- patient-dashboard END ----*/
}

/* Chat box css */

.floating-chat {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  opacity: 0;
  background: #ff6800;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: 999;
}

.floating-chat.enter:hover {
  opacity: 1;
}

.floating-chat.enter {
  transform: translateY(0);
  opacity: 0.6;
}

.floating-chat .chat-box {
  display: flex;
  flex-direction: column;
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  transition: all 250ms ease-out;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.floating-chat .chat-box.enter {
  opacity: 1;
  border-radius: 0;
  margin: 10px;
  width: auto;
  height: auto;
}

.floating-chat.expand {
  width: 350px;
  max-height: 400px;
  height: 400px;
  border-radius: 10px;
  bottom: -10px;
  cursor: auto;
  opacity: 1;
  background: none;
}

.chat-section h2 {
  background: #ff6901;
  font-size: 16px;
  color: #fff;
  display: flex;
  padding: 15px;
  border-radius: 10px 10px 0 0;
}

.floating-chat button {
  background: transparent;
  border: 0;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

[class^="scrollbar-chat"] {
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
  margin-right: 4px;
  padding-bottom: 60px;
}

.chat-box-doc {
  margin: 0px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chat-box-doc .chat {
  display: flex;
  gap: 15px;
}

.chat-box-doc .chat img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  border: solid 2px white;
}

.chat-box-doc .chat .msg {
  border-radius: 12px 12px 12px 0px;
  background: #f1f1f1;
  color: #525e6c;
  max-width: 80%;
  padding: 10px 20px;
  position: relative;
  font-size: 13px;
}

.chat-bottom-doc {
  position: absolute;
  width: 100%;
  height: 55px;
  bottom: 0;
  padding: 20px;
  border-radius: 4px;
  background: #f1f1f1;
}

.chat-bottom-doc input {
  padding: 7px;
  width: 65%;
  left: 10px;
  position: absolute;
  border: 0;
  top: 0px;
  font-size: 13px;
  background: #f1f1f1;
  color: #525e6c;
}

.chat-bottom-doc input::placeholder {
  color: #000;
}

.file-paperclip {
  position: absolute;
  bottom: 6px;
  font-size: 14px;
  left: 20px;
}

.floating-chat .send-now {
  background: #000 !important;
  color: #ffffff;
  border: 1px solid #000;
  margin: 0px 0;
  font-size: 12px;
  border-radius: 6px;
  padding: 3px 10px;
  top: 24px !important;
}

#target #wiki {
  display: flex;
  cursor: pointer;
  width: 100%;
  height: 38px;
  padding: 6px 15px 6px 20px;
  border: 1px solid #d3d8e0;
  border-radius: 6px;
  background-color: #ffffff;
  outline: 0;
  box-sizing: border-box;
  transition: background-color 0.2s;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* semi-transparent black background */
  /* backdrop-filter: blur(10px);  */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  z-index: 999;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.refresh-button {
  display: flex;
  align-items: center;
  background-color: #ff6800;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.refresh-button:hover {
  background-color: #e65c00;
}

.refresh-icon {
  margin-right: 8px;
  font-size: 20px;
}

.treatment-form input,
textarea {
  border: 1px solid #dcdee2;
  height: 35px;
  padding-left: 15px;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  border-radius: 6px;
  background-color: #fff;
  outline: none;
  text-align: left;
}

.treatment-form input::placeholder {
  color: #000;
}

.lead-search-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  padding: 8px;
  transition: border-color 0.3s ease;
}

.ding .css-13cymwt-control {
  width: 208px !important;
}
